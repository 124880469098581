import React, { useState } from 'react'
import OTPInput from '../../components/FormComponents/OTPInput'
import { Button } from 'react-bootstrap';
import TimerDisplayComponent from '../../components/FormComponents/TimerDisplayComponent';

export default function VerificationPage() {
    const [otpNumber, setOtpNumber] = useState<string | null>(null);
    const [isTimerComplete, setIsTimerComplete] = useState<boolean>(false);

    return (
        <>
            <h1>Verify your account</h1>
            <p>Almost done! We sent you a verification code to your mobile number. Please give it a visit and enter the verification code! </p>
            <p>Verification code</p>
            
            <OTPInput OTPlength={6} setOTPNumber={setOtpNumber} />

            {!isTimerComplete && <TimerDisplayComponent timeLimit={2} isTimerComplete={setIsTimerComplete} />}

            {isTimerComplete && <Button>Resend OTP</Button>}
            <Button>Submit</Button>
            <Button>Back</Button>
        </>
    )
}

import React, { useContext} from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'
import { userNameSignUp } from '../../services/AuthService';
import { signUpSchema } from '../../utilities/ValidationSchemas';
import { zodResolver } from "@hookform/resolvers/zod";
import { signUpFormType } from '../../utilities/SchemaTypes';
import ErrorMsgDisplayComponent from '../../components/FormComponents/ErrorMsgDisplayComponent';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import PhoneNumberInput from '../../components/FormComponents/PhoneNumberInput';
import { ROUTES } from '../../constants/Routes';

export default function SignUpPage() {
  const { register,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors, isSubmitting } } = useForm<signUpFormType>({
      defaultValues: {
        isMFAActive: false
      },
      resolver: zodResolver(signUpSchema)
    });
  const { setUserDetails } = useContext(AuthContext);
  const navigate = useNavigate();


  const isMFAActive = watch('isMFAActive');


  //Function triggered when the form is submitted
  const onSubmit: SubmitHandler<signUpFormType> = async (data: signUpFormType) => {
    try {
      //Clearing the anonyous user token
      localStorage.removeItem("Token");

      const response = await userNameSignUp(data);

      if (!response) setError("root", { message: "Unable to sign up user" });

      //Setting the new user token
      localStorage.setItem("Token", response.user.accessToken)

      setUserDetails(response.user);

      //Navigating back to the previous page
      navigate(ROUTES.VERIFY_USER_PAGE_ROUTE);
      
    } catch (error) {
      const err = error as Error;
      console.log("ERROR",err)
      setError("root", { message: err.message });
    }
  }

  return (
    <>
      <h2> Sign up</h2>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId='signupForm.firstName'>
          <Form.Label>First Name </Form.Label>
          <Form.Control {...register("firstName")} type="text" placeholder="John" />
          {errors.firstName && errors.firstName.message && <ErrorMsgDisplayComponent message={errors.firstName.message} />}
        </Form.Group>
        <Form.Group controlId='signupForm.lastName'>
          <Form.Label>Last Name </Form.Label>
          <Form.Control {...register("lastName")} type="text" placeholder="Doe" />
          {errors.lastName && errors.lastName.message && <ErrorMsgDisplayComponent message={errors.lastName.message} />}
        </Form.Group>
        <Form.Group controlId='signupForm.emailAddress'>
          <Form.Label>Your email </Form.Label>
          <Form.Control {...register("email")} type="text" placeholder="name@example.come" />
          {errors.email && errors.email.message && <ErrorMsgDisplayComponent message={errors.email.message} />}
        </Form.Group>
        <Form.Group controlId='signupForm.password'>
          <Form.Label>Password </Form.Label>
          <Form.Control {...register("password")} type="password" />
          {errors.password && errors.password.message && <ErrorMsgDisplayComponent message={errors.password.message} />}
        </Form.Group>
        <Form.Group controlId='signupForm.confirmPassword'>
          <Form.Label>Confirm Password </Form.Label>
          <Form.Control {...register("confirmPassword")} type="password" />
          {errors.confirmPassword && errors.confirmPassword.message && <ErrorMsgDisplayComponent message={errors.confirmPassword.message} />}
        </Form.Group>
        <Form.Check {...register("isMFAActive")} type="checkbox" label="Enable Multi-Factor Authentication (MFA) via SMS" />
        {isMFAActive &&
          <PhoneNumberInput name="mobileNumber" register={register} errors={errors.mobileNumber} setValue={setValue} />
        }
        <Button type="submit" disabled={isSubmitting}> Submit {isSubmitting && <Spinner> </Spinner>}</Button>
        <div id="sign-in-button"></div>
        {errors.root && <p>{errors.root.message}</p>}
      </Form>
    </>
  )
}

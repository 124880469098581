import React from 'react';
import './styles/App.scss';
import RouteComponent from './RouteComponent';
import { AuthContextProvider } from './context/AuthContext';

function App() {
  return (
    <AuthContextProvider>
      <RouteComponent />
    </AuthContextProvider>
  );
}

export default App;

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Navbar } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/Routes'
import { AuthContext } from '../../context/AuthContext'
import { getUserDetailsFirebaseFunc, signOutFirebaseFunc } from '../../services/AuthService'

export default function LoginHeader() {
  const { user, setUserDetails } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //Fetch user details if its null
  const fetchUser = useCallback(async () => {
    console.log("USER", user)
    if (user == null) {
      try {
        const response = await getUserDetailsFirebaseFunc();
        setUserDetails(response)
      } catch (error) {
        const err = error as Error;
        setError(err.message)
      }
    }
    setIsLoading(false);
  }, [])

  useEffect(() => {
    fetchUser()
  }, []);

  //User signout functionality
  const signout = async () => {
    const response = await signOutFirebaseFunc();

    if (!response) setError("Unable to signout user");

    localStorage.removeItem('Token');

    //Page refresh
    navigate(0);
  }

  return (
    <>
      {!isLoading &&
        <>
          {user &&
            <>
              <Navbar.Text>
                {user.firstName} {user.lastName}
              </Navbar.Text>
              <Navbar.Text>
                <Button onClick={() => signout()}>Signout</Button>
              </Navbar.Text>
            </>
          }
          {!user &&
            <>
              <Navbar.Text>
                <Link to={ROUTES.LOGIN_PAGE_ROUTE}> Login </Link>
              </Navbar.Text>
              <Navbar.Text>
                <Link to={ROUTES.SIGNUP_PAGE_ROUTE}> Sign Up </Link>
              </Navbar.Text>
            </>
          }
        </>
      }
    </>
  )
}


import React, { useContext, useEffect } from 'react'
import LoginHeader from '../UserLogin/LoginHeader';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';

export default function Header() {
    const {anonymousSignIn} = useContext(AuthContext);
    
    useEffect(() => {
        anonymousSignIn();
    },[]);

    return (
        <Navbar>
            <Container>
                <Navbar.Brand href="#home">Logo</Navbar.Brand>
                <Navbar.Text>Currency</Navbar.Text>
                <Navbar.Toggle aria-controls="main-navbar-nav" />
                <Navbar.Collapse id="main-navbar-nav">
                    <LoginHeader />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

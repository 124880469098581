import React from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom';

export default function AdminPage() {
  let adminOptions = ['dashboard', 'manage-users', 'manage-aggregators', 'manage-content'];

  const routeLocation = useLocation();

  if (routeLocation.pathname.includes('/super-admin')) adminOptions.push('manage-networkoperators');

  return (
    <div>
      {adminOptions.map((option: string) => (
        <NavLink key={option} to={`${option}`}>{option}</NavLink>
      ))}

      {/* Rending the child component */}
      <Outlet />
    </div>
  )
}

import React from 'react'

export default function HomePage() {

    return (
        <div>
            <h2>HOME </h2>
        </div>
    )
}

import React, { useRef, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

interface OTPInputProps {
    OTPlength: number,
    setOTPNumber: (OTPnumber: string) => void
}
export default function OTPInput({ OTPlength, setOTPNumber }: OTPInputProps) {
    const inputRef = useRef<(HTMLInputElement | null)[]>([]);
    const [otp, setOTP] = useState<string[]>(Array(OTPlength).fill(""));

    const handleChange = (value: string, index: number) => {
        if (!/^\d*$/.test(value)) return;

        const newOTP = [...otp]
        newOTP[index] = value;

        setOTP(newOTP);
        setOTPNumber(newOTP.join(''));

        if (value && index < OTPlength - 1) {
            inputRef.current[index + 1]?.focus();
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRef.current[index - 1]?.focus();
        }
    };

    return (
        <Form>
            <Row>
                {otp.map((digit, index) => (
                    <Col key={index}>
                        <Form.Control
                            type="text"
                            maxLength={1}
                            value={digit}
                            onChange={(e) => handleChange(e.target.value, index)}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, index)}
                            ref={(el: any) => (inputRef.current[index] = el)} />
                    </Col>
                ))}
            </Row>
        </Form>
    )
}

import React, { createContext, useEffect, useMemo, useState } from 'react'
import { anonymousSignInFunc, ssoSignInFunc } from '../services/AuthService';
import { AxiosError } from 'axios';

//Interface for the user type
export interface UserType {
    firstName: string;
    lastName: string;
    mobileNumber: string;
    emailAddress: string;
    isMFAEnabled: boolean;
};

//Interface for the auth context type
export interface AuthContextType {
    ssoSignIn: () => Promise<boolean>;
    anonymousSignIn: () => void;
    signOut: () => void;
    setUserDetails: (user: any) => void;
    user: UserType | null;
}

//Interface for the react layout type
interface LayoutType {
    children?: React.ReactNode;
}

export const AuthContext = createContext<AuthContextType>({
    ssoSignIn: async () => false,
    anonymousSignIn: () => { },
    signOut: () => { },
    setUserDetails: () => { },
    user: null,
});

export const AuthContextProvider: React.FC<LayoutType> = ({ children }) => {
    const [user, setUser] = useState<UserType | null>(null);

    //common functionality to set the user details
    const setUserDetails = (data: any) => {
        console.log("Setting user details", data)
        const currentUser: UserType = {
            firstName: data.displayName.split(" ")[0],
            lastName: data.displayName.split(" ")[1],
            mobileNumber: data.phoneNumber,
            emailAddress: data.email,
            isMFAEnabled: data.phoneNumber ? true : false
        };

        setUser(currentUser)
    };

    //Google SSO login function
    const ssoSignIn = async (): Promise<boolean> => {
        try {
            const response = await ssoSignInFunc();

            if (!response) throw new Error("Unable to complete SSO login");

            setUserDetails(response.user);

            localStorage.setItem('Token', response.user.accessToken);

            return true;
        } catch (error) {
            const err = error as AxiosError;
            throw new Error(err.message);
        }
    };

    //Anonymous user sign in funciton used to sign in a user anonymously when they visit the site, this is to keep the API end points always protected with a token. 
    const anonymousSignIn = async () => {
        const currentToken = localStorage.getItem('Token');

        //Preventing the token generation if a token is prevailing
        if (currentToken) return;

        try {
            const response = await anonymousSignInFunc();

            if (!response) throw new Error("Unable to complete anonymous sign-in");

            setUser(null);

            localStorage.setItem('Token', response.accessToken);
        } catch (error) {
            const err = error as AxiosError;
            throw new Error(err.message);
        }
    };

    const signOut = () => {

    }

    const contextValue: AuthContextType = useMemo(() => {
        return {
            ssoSignIn,
            anonymousSignIn,
            signOut,
            setUserDetails,
            user
        }
    }, [user]);


    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}

export const ROUTES = {
    HOMEPAGE_ROUTE: '/',
    SERVICES_PAGE_ROUTE: '/services',
    PROMOTIONS_PAGE:'/promotions',
    ABOUTUS_PAGE_ROUTE: '/about-us',
    PRIVACY_POLICY_ROUTE: '/privacy-policy',
    TERMS_CONDITION_ROUTE: '/terms-and-condition',
    ADMIN_LOGIN_PAGE_ROUTE: '/login/admin',
    LOGIN_PAGE_ROUTE: '/login',
    SIGNUP_PAGE_ROUTE: '/sign-up',
    VERIFY_USER_PAGE_ROUTE:'/verify-user',
    ADMIN_PAGE_ROUTE:'/admin',
    SUPERADMIN_PAGE_ROUTE: '/super-admin',
}
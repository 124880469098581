import React from 'react'

interface ErrorMsgComponentType {
    message: string
}

export default function ErrorMsgDisplayComponent({ message }: ErrorMsgComponentType) {
    return (
        <p className="text-danger">{message}</p>
    )
}

import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap';
import { ROUTES } from '../../constants/Routes';
import { Link } from 'react-router-dom';


export default function SecondaryHeader() {
  return (
    <div>
      <Navbar>
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link to={ROUTES.HOMEPAGE_ROUTE}>Home</Link>
              <Link to={ROUTES.PROMOTIONS_PAGE}>Promotions</Link>
              <Link to={ROUTES.SERVICES_PAGE_ROUTE}>Services</Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

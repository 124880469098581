// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey:"AIzaSyDYFrQYQo3d_IstSkUKStXFTYuXHycWJZQ",
    authDomain: "testfastapi-426015.firebaseapp.com",
    projectId: "testfastapi-426015",
    storageBucket: "testfastapi-426015.appspot.com",
    messagingSenderId: "311087931382",
    appId: "1:311087931382:web:85d36a85d3124b299b31d7"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
    prompt: "select_account "
});

export const auth = getAuth(app);

export const tenantId = auth.tenantId;

export const signInWithGooglePopup = () => signInWithPopup(auth, provider);

export default app;
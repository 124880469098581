import React, { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/Routes';


export default function LoginPage() {

  const {ssoSignIn} = useContext(AuthContext);
  const navigate = useNavigate();

  const [error, setError] = useState<string>("");

  const loginUser = async () => {
    try {
      const response = await ssoSignIn();

      if(!response) setError("Error in Signing in user");

      navigate(ROUTES.HOMEPAGE_ROUTE);
      
    } catch (error) {
      setError("Error in Signing in user");
    }
  }

  return (
    <div>
      LoginPage

      <button onClick={loginUser}>Sign In With Google</button>
    </div>
  )
}

import { auth, signInWithGooglePopup } from "../firebase";
import { FirebaseError } from "firebase/app";
import { createUserWithEmailAndPassword, multiFactor, onAuthStateChanged, PhoneAuthProvider, RecaptchaVerifier, signInAnonymously, signOut, updateProfile, User } from "firebase/auth";
import { signUpFormType } from "../utilities/SchemaTypes";

//Firebase user name password sign in method
export const userNameSignUp = async (userData: signUpFormType): Promise<any> => {
    try {
        const response = await createUserWithEmailAndPassword(auth, userData.email, userData.password);

        if (!response) throw new Error("Unable to sign up user");

        const currentUser = response.user;

        await updateProfile(currentUser, {
            displayName: `${userData.firstName} ${userData.lastName}`
        })

        console.log("response", response)

        if (!userData.isMFAActive) return response;

        //Rendering the invisible recaptcha for 2FA
        const recaptchaVerifier:RecaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", { "size": "invisible", });

        await mobileAuthFunc(currentUser, recaptchaVerifier, userData.mobileNumber);

        return response;

    } catch (error) {
        if (error instanceof FirebaseError) {
            console.log("set",error.code)
            throw new Error('Sign up failed with error code:',error)
        } else {
            throw new Error('Sign up failed.')
        }
    }
}

/**
 * 2FA mobile auth functionality
 * @param user 
 * @param mobileNumber 
 * @returns 
 */
export const mobileAuthFunc = async (user: User, recaptchaVerifier:RecaptchaVerifier, mobileNumber: string | undefined): Promise<any> => {
    try {
        console.log("verifier", recaptchaVerifier);

        const multiFactorSession = await multiFactor(user).getSession();

        const phoneInfoOptions = {
            phoneNumber: mobileNumber,
            session: multiFactorSession
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);

        // Send SMS verification code.
        const verificationID =  phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);

        console.log(verificationID);

        return verificationID;
    } catch (error) {
        recaptchaVerifier.clear();
        throw new Error('Unable to enroll user to 2FA');
    }
};

/**
 * OTP Verification check
 * @param otpCode 
 * @param verificationID 
 */
export const mobileVerificationFunc = async (otpCode:string, verificationID:string): Promise<any> => {
    try {
        // const credentials = PhoneAuthProvider.credential(verificationID, otpCode);

        // const multiFactorCheck = PhoneMultiFactorGenerator.assertion(credentials);

        //ONCE FIREBASE IS READY TEST IT OUT
    } catch(error){
        if (error instanceof FirebaseError) {
            throw new Error('Sign in failed with error code:', error)
        } else {
            throw new Error('Sign in failed.')
        }
    }
}

/**
 * Firebase Google SSO method
 * @returns 
 */
export const ssoSignInFunc = async (): Promise<any> => {
    try {
        const response = await signInWithGooglePopup();

        if (!response) throw new Error("Unable to sign in user");

        return response;

    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Sign in failed with error code:', error)
        } else {
            throw new Error('Sign in failed.')
        }
    }
}

/**
 * Firebase anonymous sign in method
 */
export const anonymousSignInFunc = async (): Promise<any> => {
    try {
        const response = await signInAnonymously(auth);

        if (!response) throw new Error("Anonymous sign in failed");

        let currentUser = null;

        await onAuthStateChanged(auth, (user) => {
            if (!user) throw new Error("Anonymous sign in failed");
            currentUser = user;
        });

        console.log("sign in anonymous");
        return currentUser;
    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Anonymous sign in failed with error code:', error)
        } else {
            throw new Error('Anonymous sign in failed.')
        }
    }
}


/**
 * Get user details from firebase
 * @returns 
 */
export const getUserDetailsFirebaseFunc = async (): Promise<any> => {
    try {
        return new Promise((resolve) => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    resolve(user); // User is signed in, resolve with user details
                } else {
                    resolve(null); // User is signed out, resolve with null
                }
            });
        });

    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Unable to fetch user details:', error)
        } else {
            throw new Error('Unable to fetch user details.')
        }
    }
}

/**
 * Signout user from firebase
 * @returns 
 */
export const signOutFirebaseFunc = async (): Promise<boolean> => {
    try {
        await signOut(auth);
        return true;
    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Unable to signout firebase user:', error)
        } else {
            throw new Error('Unable to signout firebase user.')
        }
    }
}

/**
 * Send user verification code
 */
export const sendOTPVerification = async (): Promise<any> => {
    try {

    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Unable to send verification code:', error)
        } else {
            throw new Error('Unable to send verification code.')
        }
    }
}

import React from 'react'
import { useParams } from 'react-router-dom'
import Dashboard from '../../components/AdminComponents/Dashboard';
import ManageUsers from '../../components/AdminComponents/ManageUsers';
import ManageContent from '../../components/AdminComponents/ManageContent';
import ManageAggregators from '../../components/AdminComponents/ManageAggregators';
import ManageNetworkOperators from '../../components/AdminComponents/ManageNetworkOperators';

export default function ManagementPage() {
    const params = useParams<{ managementType: string }>();

    const managementType = params.managementType;

    return (
        <div>
            {managementType == 'dashboard' && <Dashboard /> }
            {managementType == 'manage-users' && <ManageUsers/>}
            {managementType == 'manage-aggregators' && <ManageAggregators/>}
            {managementType == 'manage-content' && <ManageContent/>}
            {managementType == 'manage-networkoperators' && <ManageNetworkOperators/>}
        </div>
    )
}

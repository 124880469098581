import React from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ROUTES } from './constants/Routes';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';

//Error page
import NotFoundPage from './pages/NotFoundPage';

//Content pages
import AboutUsPage from './pages/ContentPages/AboutUsPage';
import PrivacyPolicyPage from './pages/ContentPages/PrivacyPolicyPage';
import TermsConditionPage from './pages/ContentPages/TermsConditionPage';
import LoginPage from './pages/LoginPages/LoginPage';
import SignUpPage from './pages/LoginPages/SignUpPage';
import PromotionsPage from './pages/PromotionsPage';
import AdminLayout from './layout/AdminLayout';
import ManagementPage from './pages/AdminPages/ManagementPage';
import CustomerLayout from './layout/CustomerLayout';
import VerificationPage from './pages/LoginPages/VerificationPage';

const router = createBrowserRouter([
    {
        element: <CustomerLayout />,
        children: [
            {
                path: `${ROUTES.HOMEPAGE_ROUTE}`,
                element: <HomePage />,
                errorElement: <NotFoundPage />
            },
            {
                path: `${ROUTES.SERVICES_PAGE_ROUTE}`,
                element: <ServicesPage />
            },
            {
                path: `${ROUTES.PROMOTIONS_PAGE}`,
                element: <PromotionsPage />
            },
            //Content pages
            {
                path: `${ROUTES.ABOUTUS_PAGE_ROUTE}`,
                element: <AboutUsPage />
            },
            {
                path: `${ROUTES.PRIVACY_POLICY_ROUTE}`,
                element: <PrivacyPolicyPage />
            },
            {
                path: `${ROUTES.TERMS_CONDITION_ROUTE}`,
                element: <TermsConditionPage />
            },

        ]
    },

    //Login pages
    {
        path: `${ROUTES.ADMIN_LOGIN_PAGE_ROUTE}`,
        element: <LoginPage />
    },
    {
        path: `${ROUTES.LOGIN_PAGE_ROUTE}`,
        element: <LoginPage />
    },
    {
        path: `${ROUTES.SIGNUP_PAGE_ROUTE}`,
        element: <SignUpPage />
    },
    {
        path: `${ROUTES.VERIFY_USER_PAGE_ROUTE}`,
        element: <VerificationPage />
    },

    //Admin pages
    {
        path: `${ROUTES.ADMIN_PAGE_ROUTE}`,
        element: <AdminLayout />,
        children: [
            {
                path: `${ROUTES.ADMIN_PAGE_ROUTE}/:managementType`,
                element: <ManagementPage />
            }
        ]
    },

    //Super Admin pages
    {
        path: `${ROUTES.SUPERADMIN_PAGE_ROUTE}`,
        element: <AdminLayout />,
        children: [
            {
                path: `${ROUTES.SUPERADMIN_PAGE_ROUTE}/:managementType`,
                element: <ManagementPage />
            }
        ]
    }
]);



export default function RouteComponent() {
    return (
        <RouterProvider router={router} />
    )
}


import React from 'react'
import Header from '../components/Layout/Header'
import SecondaryHeader from '../components/Layout/SecondaryHeader'
import { Outlet } from 'react-router-dom'
import Footer from '../components/Layout/Footer'

export default function CustomerLayout() {
  return (
    <>
        <Header/>
        <SecondaryHeader/>
        <Outlet/>
        <Footer/>
    </>
  )
}

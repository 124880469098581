/**
 * All the validation schemas of the system will be maintained in this file
 */

import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

export const signUpSchema = z.object({
    firstName: z.string().min(1, "Please enter the first name"),
    lastName: z.string().min(1, "Please enter the last name"),
    email: z.string().email("Please enter a valid email address"),
    password: z.string()
        .min(8, "Password must be at least 8 characters long")
        .regex(/[a-z]/, "Password must contain at least one lowercase letter")
        .regex(/[A-Z]/, "Password must contain at least one upper case letter")
        .regex(/[0-9]/, "Password must contain at least one number")
        .regex(/[^a-zA-Z0-9]/, "Password must contain at least one specal character"),
    confirmPassword: z.string(),
    isMFAActive: z.boolean(),
    mobileNumber: z.string()
        .optional()
        .refine((value) => !value || isValidPhoneNumber(value), {
            message: "Please enter a valid mobile number"
        })
}).refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["cofirmPassword"]
}).refine((data) => {
    if (data.isMFAActive) {
        return data.mobileNumber && data.mobileNumber.length > 0;
    }
    return true;
}, {
    message: "Please enter a mobile number",
    path: ["mobileNumber"]
});
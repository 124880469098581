import { E164Number } from 'libphonenumber-js/types.cjs';
import React from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { FieldError, FieldValues, Path, UseFormRegister } from 'react-hook-form';
import ErrorMsgDisplayComponent from './ErrorMsgDisplayComponent';

interface PhoneNumberInputProps<T extends FieldValues> {
    name: Path<T>;
    register: UseFormRegister<T>;
    errors?: FieldError;
    setValue: (name: Path<T>, value: any) => void;
}

export default function PhoneNumberInput<T extends FieldValues>({ name, register, errors, setValue }: PhoneNumberInputProps<T>) {

    const handleChange = (value: E164Number | undefined) => {
        setValue(name, value?.toString());
    }

    return (
        <>
            <PhoneInput
                placeholder="Enter number"
                {...register(name)}
                onChange={handleChange} />
            {errors && errors.message && <ErrorMsgDisplayComponent message={errors.message} />}
        </>
    )
}

import React from 'react'
import { ORGANIZATION_DETAILS } from '../../constants/OrganizationDetails'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../constants/Routes'

export default function Footer() {
  return (
    <div>
      <h3>We're Here to Help!</h3>
      <p> Need help or have questions? Our support team is available via email, or phone to assist you.</p>
      {ORGANIZATION_DETAILS.EMAIL_ADD && <p>Email Us: {ORGANIZATION_DETAILS.EMAIL_ADD}</p>}
      {ORGANIZATION_DETAILS.CONTACT_NUMBER && <p>Call us: {ORGANIZATION_DETAILS.CONTACT_NUMBER}</p>}
      {ORGANIZATION_DETAILS.ADDRESS && <p>Visit Us: {ORGANIZATION_DETAILS.ADDRESS}</p>}

      <p>Copyright © 2024 GreenLeaf. All rights reserved.</p>
      <p>Login as an Admin</p>
      <Link to={ROUTES.ABOUTUS_PAGE_ROUTE}>About us</Link>
      <Link to={ROUTES.PRIVACY_POLICY_ROUTE}>Privacy Policy</Link>
      <Link to={ROUTES.TERMS_CONDITION_ROUTE}>Terms and Condition</Link>
    </div>
  )
}

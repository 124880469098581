import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../constants/Routes'

export default function NotFoundPage() {
  return (
    <div>
        404 Page Not found
        <Link to={ROUTES.HOMEPAGE_ROUTE}>Back to Home</Link>
    </div>

  )
}
